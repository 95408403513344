require('../dev_html/build/js/jquery-3.5.1.min.js');


$(document).ready(function () {

    if (!$('.page-top .cover-img').length & !$('.hero .cover-img').length & !$('.page-top__embed').length) {
        $('header').addClass('active');
        $('header').addClass('h-active');
    }

});

require('../dev_html/build/js/main.js');
require('../dev_html/build/js/swiper.min.js');
require('./bootstrap');

$(document).ready(function () { 

    $('.link--more.js-toggle-description').click(function() {
        $('.person-about-short').toggleClass('js-description-show');
    });


    $('.socials .social--link').on('click', function (e) {

        e.preventDefault();
        let link = $(e.target).attr('href');

        navigator.clipboard.writeText(link).then(function() {
            alert('Ссылка скопирована в буфер обмена.');
        }, function(err) {
            alert('Не получилось скопировать ссылку.');
        });
    });

    $('.js-action-link').click(function (e) {

        e.preventDefault();

        $.ajax({
            url: $(e.target).attr('href')
        }).done((data) => {
            window.location.reload();
        });
    });

    if ($('.js-infinite-scroll').length > 0) (function () {

        var page = 1;
        const next = () => {

            page++;

            let url = new URL(window.location.href);
            url.searchParams.set('page', page);

            $.ajax({
                url: url,
            }).done((data) => {

                if (data.length > 0) {
                    $('.js-infinite-scroll__stub').hide();
                    $('.js-infinite-scroll').first().append(data);
                } else {
                    $('.js-infinite-scroll__stub').show();
                }

                $('.js-articles-count').text( $('.js-infinite-scroll').first().children('.js-infinite-scroll__total').text());
                $('.js-infinite-scroll').first().children('.js-infinite-scroll__total').remove();
            });
        }

        $(window).on('scroll', () => {

            let container = $('.js-infinite-scroll').first();
            if (($(window).scrollTop() + $(window).height()) >= (container.offset().top + container.height())) {
                next();
            }
        });

        $('.js-filter-option').on('change', (e) => {

            let input = $(e.target);
            let url = new URL(window.location.href);

            let values = url.searchParams.get(input.attr('name'));
            if (!values) {
                values = '';
            }
            values = values.split(',');

            if (input.is(':radio')) {
                console.log('radio');
                values = [input.attr('value')];
            } else {
            if (input.is(':checked')) {
                values.push(input.attr('value'));
            } else {
                values = values.filter(function(e) {
                    return e !== input.attr('value')
                });
            }
            }

            url.searchParams.set(input.attr('name'), values.join());
            window.history.replaceState(null, null, url);

            $('.js-infinite-scroll').first().children('.js-infinite-scroll__data').remove();
            page = 0;
            next();
        });

        $(".js-search-input").keyup(function(event){
            if(event.keyCode == 13) {
                event.preventDefault();
                let url = new URL(window.location.href);
                let query = $('.js-search-input').val().trim();
                if (query.length == 0) {
                    url.searchParams.delete('search');
                } else {
                    url.searchParams.set('search', query);
                }
                window.history.replaceState(null, null, url);

                $('.js-infinite-scroll').first().children('.js-infinite-scroll__data').remove();
                page = 0;
                next();
            }
        });

        $('.js-search-button').on('click', (e) => {

            let url = new URL(window.location.href);
            let query = $('.js-search-input').val().trim();
            if (query.length == 0) {
                url.searchParams.delete('search');
            } else {
                url.searchParams.set('search', query);
            }
            window.history.replaceState(null, null, url);

            $('.js-infinite-scroll').first().children('.js-infinite-scroll__data').remove();
            page = 0;
            next();
        });
    })();

    $('#avatar-input').on('change', function (e) {

        const [file] = $(e.target).get(0).files
        if (file) {
            $('#avatar-preview').attr('src', URL.createObjectURL(file));
        }

    });

    if(window.location.hash) {
        $('.p-accordeon-content').show();
        $([document.documentElement, document.body]).animate({
            scrollTop: $(window.location.hash).offset().top - 80
        }, 500);
    }
   
    /*Swiper('.swiper-container-horizontal', {
        autoplay: {
	    delay : 5000
        }
    });*/
 
})


