$(document).ready(function () {
    if (!$(".h-active").length) {
        //if main page - do header scroll events
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 100) {
                $(".h").addClass("active");
            } else {
                $(".h").removeClass("active");
            }
        });
    }
    if ($(window).scrollTop() > 100) {
        $(".h").addClass("active");
    }

    $(".toggle-menu").on("click", function () {
        if ($(window).width() > 992) {
            $(".mob-menu,.toggle-menu,.backdrop").toggleClass("active");
            // if (!$(".h").hasClass("h-active")) {
            $(".h").toggleClass("active-desktop");
            // }
            if (!$(".h").hasClass("h-active")) {
                if (!$(".h").hasClass("active")) {
                    $(".h").toggleClass(" active");
                }
            }
        } else {
            $(".mob-menu,.backdrop,.toggle-menu").addClass("active");
        }
        if (
            $(window).scrollTop() === 0 &&
            !$(".toggle-menu").hasClass("active") &&
            !$(".h").hasClass("h-active")
        ) {
            $(".h").removeClass(" active");
        }
    });
    $(".backdrop").on("click", function () {
        $(".mob-menu,.backdrop,.toggle-menu").removeClass("active");
    });

    if ($(".prf-tab").length) {
        //tabs changing
        $(".prf-tab").on("click", function () {
            $(".prf-tab").removeClass("active");
            $(this).addClass("active");

            //content tabs changing
            var target = $(this).attr("data-tab-id");
            $(".prf-content").removeClass("active");
            $(".prf-content[data-tab-target='" + target + "']").addClass(
                "active"
            );
        });
    }

    if ($(".modal-trigger").length) {
        //show modal
        $(".modal-trigger").on("click", function () {
            const target = $(this).attr("data-modal-id");
            $(".modal[data-modal='" + target + "']").addClass("active");
        });

        //hide modal
        $(".modal").on("click", function (e) {
            if (
                $(e.target).hasClass("btn-close") ||
                $(e.target).hasClass("modal") ||
                $(e.target).hasClass("modal-close")
            ) {
                $(".modal").removeClass("active");
            }
        });
    }

    //hide btn
    if ($(".modal-close").length) {
        $(".modal-close").on("click", function () {
            $(".filters-wrapper, .modal").removeClass("active");
        });
    }

    //show filters
    if ($(window).width() < 1200) {
        $(".filter-toggle").on("click", function () {
            $(".filters-wrapper").addClass("active");
        });
    }

    if ($(".filter-item").length) {
        //toggle filter block
        $(".filter-item-title").on("click", function () {
            $(this).toggleClass("active");
            $(this).next().slideToggle(300);
            // $(this).siblings(".filter-content-shadow").toggleClass("active");
        });

        //add shadow if items in filter > 4
        $(".filter-item").each(function () {
            if ($(this).find(".filter-content")[0].childElementCount > 3) {
                $(this).append('<div class="filter-content-shadow"></div>');
            }
        });
    }

    //main page video
    if ($(".hero").length) {
        $(".btn-play").on("click", function () {
            $(".hero video").trigger("play");
        });
    }

    //accordeion single page
    if ($(".p-accordeon-title").length) {
        $(".p-accordeon-title").on("click", function () {
            $(this).toggleClass("active").next().slideToggle();
        });
    }

    // $('a[href^="#"]').on('click',function(){
    //     var target = $(this).attr('href');
    //     $('html, body').animate({scrollTop: $(target).offset().top - 90}, 800);
    //     $('.menu, .menu a').removeClass('active')
    //     $(this).addClass('active')
    //     return false;
    // });

    //hide block if click outside
    // $(document).on("click", function (e) {
    //   if (
    //     $(e.target).closest(
    //       ".js-chat-add-participants,.js-chat-add-participants-list,.js-dropdown-body,.js-dropdown-btn,.js-search,.js-search-input"
    //     ).length
    //   )
    //     return; // ОТМЕНА, ЕСЛИ КЛИК ПО ДИВУ ИЛИ ДЕТЕЙ
    //   $(
    //     ".js-dropdown-body,.js-search-input,.js-chat-add-participants-list"
    //   ).removeClass("active");
    // });

    //search
    // if ($(".js-search").length) {
    //   $(".js-search").on("click", function () {
    //     $(".js-search-input").toggleClass("active");
    //   });
    // }

    //sliders
    function hidePrevBtn(slider, sclass) {
        slider.on("slideChange", function (index) {
            if (index.snapIndex != 0) {
                $(sclass).find(".swiper-button-prev").addClass("active");
            } else {
                $(sclass).find(".swiper-button-prev").removeClass("active");
            }
 
        });
    }
    if ($(".news-hot-slider").length) {
        const hero = new Swiper(".news-hot-slider", {
            spaceBetween: 16,
            slidesPerView: 1.12,

            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                992: {
                    navigation: {
                        nextEl: ".swiper-button-hot-next",
                        prevEl: ".swiper-button-hot-prev",
                    },
                    spaceBetween: 32,
                    slidesPerView: 1.3,
                },
            },
        });

        hidePrevBtn(hero, ".news-hot");
    }
    if ($(".experts-slider").length) {
        const experts = new Swiper(".experts-slider", {
            slidesPerView: 1,
            spaceBetween: 1,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: ".swiper-button-expert-next",
                prevEl: ".swiper-button-expert-prev",
            },

            pagination: {
                el: ".experts-pagination",
                type: "bullets",
            },

            breakpoints: {
                768: {
                    pagination: false,
                },
            },
        });

        hidePrevBtn(experts, ".experts-slider");
    }

    
    if ($(".authors-slider").length) {  
            if($(".authors-slider").find('.swiper-slide').length > 1) {
                 
              const experts = new Swiper(".authors-slider", {
                slidesPerView: 1.2,
                spaceBetween: 20,
                navigation: {
                    nextEl: ".swiper-button-authors-next",
                    prevEl: ".swiper-button-authors-prev",
                },

                breakpoints: {
                    576: { 
                        slidesPerView: 2.6,
                    }
                },
            });

              hidePrevBtn(experts, ".authors-slider");
          } else {
            $(this).find('.swiper-button').hide()
          } 

    }

    if ($(".partners-slider").length) {
        const partners = new Swiper(".partners-slider", {
            slidesPerView: 1,
            spaceBetween: 16,
            autoplay: {
                delay: 5000,
            },

            pagination: {
                el: ".partners-pagination",
                type: "bullets",
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                },
                768: {
                    spaceBetween: 32,
                    slidesPerView: 2,
                    pagination: false,
                    navigation: {
                        nextEl: ".swiper-button-partner-next",
                        prevEl: ".swiper-button-partner-prev",
                    },
                },
            },
        });
        hidePrevBtn(partners, ".partners-slider");
    }

    //toggle sidebar in mob
    if ($(".js-toggle-sidebar").length) {
        $(".js-toggle-sidebar").on("click", function () {
            $(".expert-sidebar,.backdrop").addClass("active");
        });

        $(".close-sidebar,.backdrop").on("click", function () {
            $(".expert-sidebar,.backdrop").removeClass("active");
        });
    }

    if ($(".prf-follow-slider .slider").length) {
        const prfFollow = new Swiper(".prf-follow-slider .slider", {
            navigation: {
                nextEl: ".swiper-button-follow-next",
                prevEl: ".swiper-button-follow-prev",
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
            },
        });
        hidePrevBtn(prfFollow, ".prf-follow-slider .slider");
    }
    //toggle description
    if ($(".js-toggle-description").length) {
        let descriptionFull;

        $(".js-toggle-description").on("click", function () {
            $(".js-description").toggleClass("active");

            if (!descriptionFull) {
                $(this).text("Скрыть");
            } else {
                $(this).text("Читать далее");
            }

            descriptionFull = !descriptionFull;
        });
    }

    if ($(".themes-wrapper").length && $(window).width() < 992) {
        const hero = new Swiper(".themes-wrapper", {
            slidesPerView: 1.22,
            spaceBetween: 16,

            breakpoints: {
                480: {
                    slidesPerView: 2.2,
                },
                768: {
                    slidesPerView: 3.2,
                },
            },
        });
    }

    if ($(".authors-slider-mob").length && $(window).width() < 992) {
        const authors = new Swiper(".authors-slider-mob", {
            slidesPerView: 1, 
        });
    }

    if ($("select").length) {
        // $("select").selectize({
        // placeholder: $(this).attr("data-placeholder"),
        // score: false,
        // onInitialize: function () {
        //     this.$control_input.attr("readonly", true);
        // },
        // });
        $("select").selectric({
            arrowButtonMarkup: "",
        });
    }

    //tabs
    // if ($(".js-tab").length) {
    //   $(".js-tab").on("click", function () {
    //     const t = $(this);
    //     const tabTraget = t.attr("data-tab-id");

    //     $(".js-tab,.tab-item").removeClass("active");
    //     t.addClass("active");

    //     $('.tab-item[data-tab-item="' + tabTraget + '"]').addClass("active");
    //   });
    // }

    // //if post items exist cut title height
    // function cutTitleHeight(title, maxSymbols) {
    //   const t = title;
    //   const changeLastWord = t.text().split(""); //get letters length

    //   if (t.height() > changeLastWord.length) {
    //     // if length > max lenth

    //     extraLetters = changeLastWord.length - maxSymbols; // get extra lettrs num

    //     changeLastWord.splice(maxSymbols, extraLetters, "..."); // remove extra letters and add dots to string ending

    //     t.text(changeLastWord.join("")); //rewrite title
    //   }
    // }

    // $(".aticle-slide--title").each(function () {
    //   cutTitleHeight($(this), 52);
    // });

    // $(".post-item--title").each(function () {
    //   cutTitleHeight($(this), 65);
    // });
    // $(".post-item--title-small").each(function () {
    //   cutTitleHeight($(this), 90);
    // });

    if($('.quote').length) {
        let quoteS = "«";
        let quoteE = "»";

        if($('html').attr('lang') === 'en') {
            quoteS = "“";
            quoteE = "”";
        }

        $('.quote').each(function() {
            const text = $(this).text();

            let firstP = $(this).children(":first").text();
            console.log('firstP', firstP);
            let lastP = $(this).children(":last").text();
            console.log('lastP', lastP);

            $(this).children(":first").html("<p>"+quoteS+firstP+"</p>");
            $(this).children(":last").html("<p>"+lastP+quoteE+"</p>");

        });

    }
});

// document.fonts.ready.then(function() {
//     $('.loader').hide()
// });
